/* eslint-disable react/no-unescaped-entities */
import React, { FC } from "react";

export const Sunset: FC = () => {
    const imagesA = [
        { src: "/images/01.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "4/3" },
        { src: "/images/02.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/03.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/04.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/05.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/06.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
    ];
    const imagesB = [
        { src: "/images/07.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/08.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/09.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/10.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/11.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/12.png", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
    ];
    const imagesC = [
        { src: "/images/13.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/14.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/15.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/18.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
        { src: "/images/17.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "1/1" },
        { src: "/images/16.jpeg", alt: "Lorem ipsum dolor sit amet", ratio: "3/4" },
    ];

    return (
        <div>
            <div className="m-auto flex min-h-screen w-full max-w-[1920px] items-stretch justify-center md:px-6">
                <div className="grid w-full grid-cols-12 gap-6 md:flex-1">
                    <div className="col-span-12 flex items-end justify-center md:col-span-8 md:items-center lg:col-span-6">
                        <div className="relative mt-96 w-full flex-1 bg-white py-12 before:absolute before:-top-48 before:h-48 before:w-full before:bg-gradient-to-t before:from-white before:content-[''] md:mt-0">
                            <div className="m-auto max-w-[472px] space-y-6 px-6 text-center">
                                <h1 className="text-4xl font-bold leading-none">
                                    The Next Step for Avant Arte's Community
                                </h1>
                                <div className="space-y-3">
                                    <p>Hey everyone,</p>
                                    <p>
                                        We've had to make the tough call to wrap up the Collective. It's a move forward
                                        for Avant Arte and a step towards fresh ways to connect with you.
                                    </p>
                                    <p>
                                        We're grateful for your support as well as the good times we've had, the art
                                        we've shared, and the community we've built. This isn't goodbye, just a change
                                        of scenery.
                                    </p>
                                    <p>
                                        We're closing the Collective, but our community isn't going anywhere. Let's keep
                                        the conversation going on our other platforms. More details to follow soon.
                                    </p>
                                    <p>
                                        See you there,
                                        <br />
                                        The Avant Arte team.
                                    </p>
                                </div>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        className="transition duration-300 ease-out hover:text-brown-light"
                                        href="https://www.instagram.com/avant.arte/"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <svg
                                            fill="none"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                clipRule="evenodd"
                                                d="M15.75 4.5C16.7437 4.5 17.6859 4.89375 18.3984 5.60156C19.1109 6.30938 19.5 7.25625 19.5 8.25V15.75C19.5 16.7437 19.1063 17.6859 18.3984 18.3984C17.6906 19.1109 16.7437 19.5 15.75 19.5H8.25C7.25625 19.5 6.31406 19.1063 5.60156 18.3984C4.88906 17.6906 4.5 16.7437 4.5 15.75V8.25C4.5 7.25625 4.89375 6.31406 5.60156 5.60156C6.30938 4.88906 7.25625 4.5 8.25 4.5H15.75ZM15.75 3H8.25C5.3625 3 3 5.3625 3 8.25V15.75C3 18.6375 5.3625 21 8.25 21H15.75C18.6375 21 21 18.6375 21 15.75V8.25C21 5.3625 18.6375 3 15.75 3ZM16.875 8.25C16.2516 8.25 15.75 7.74844 15.75 7.125C15.75 6.50156 16.2516 6 16.875 6C17.4937 6 18 6.50156 18 7.125C18 7.74844 17.4937 8.25 16.875 8.25ZM15 12C15 10.3453 13.6547 9 12 9C10.3453 9 9 10.3453 9 12C9 13.6547 10.3453 15 12 15C13.6547 15 15 13.6547 15 12ZM7.5 12C7.5 9.51562 9.51562 7.5 12 7.5C14.4844 7.5 16.5 9.51562 16.5 12C16.5 14.4844 14.4844 16.5 12 16.5C9.51562 16.5 7.5 14.4844 7.5 12Z"
                                                fill="currentColor"
                                                fillRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                    <a
                                        className="transition duration-300 ease-out hover:text-brown-light"
                                        href="https://twitter.com/avant_arte"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <svg
                                            fill="none"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.533 7.23514C21.5482 7.44294 21.5482 7.65079 21.5482 7.85859C21.5482 14.1968 16.599 21.5 7.5533 21.5C4.76648 21.5 2.17767 20.7132 0 19.3477C0.395953 19.3922 0.776625 19.407 1.18781 19.407C3.48727 19.407 5.60405 18.65 7.29441 17.3586C5.13197 17.3141 3.31978 15.9336 2.69541 14.0336C3 14.0781 3.30455 14.1078 3.62437 14.1078C4.06598 14.1078 4.50764 14.0484 4.91878 13.9445C2.66498 13.4992 0.974578 11.5695 0.974578 9.23905V9.1797C1.62937 9.53595 2.39086 9.75861 3.19791 9.78826C1.87303 8.9273 1.00505 7.45779 1.00505 5.79528C1.00505 4.90467 1.24866 4.08826 1.67508 3.37576C4.09641 6.28513 7.73602 8.1851 11.8172 8.39295C11.7411 8.0367 11.6954 7.66564 11.6954 7.29454C11.6954 4.65232 13.8883 2.5 16.6141 2.5C18.0304 2.5 19.3095 3.0789 20.208 4.01406C21.3197 3.80626 22.3857 3.40546 23.3299 2.85625C22.9643 3.96956 22.1877 4.90472 21.1674 5.49842C22.1573 5.39457 23.1167 5.12732 23.9999 4.75627C23.33 5.70623 22.4924 6.55229 21.533 7.23514V7.23514Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a
                                        className="transition duration-300 ease-out hover:text-brown-light"
                                        href="https://discord.com/invite/8fReZrWtAP"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <svg
                                            fill="none"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.2687 5.3297C17.9386 4.70986 16.4986 4.25998 14.9985 4.00005C14.9854 3.99963 14.9723 4.00209 14.9602 4.00727C14.9481 4.01246 14.9373 4.02023 14.9285 4.03004C14.7485 4.35995 14.5385 4.78984 14.3985 5.11975C12.8075 4.87982 11.1894 4.87982 9.59839 5.11975C9.45838 4.77984 9.24838 4.35995 9.05837 4.03004C9.04837 4.01004 9.01837 4.00005 8.98837 4.00005C7.48833 4.25998 6.05829 4.70986 4.71825 5.3297C4.70825 5.3297 4.69825 5.3397 4.68825 5.34969C1.96818 9.41863 1.21816 13.3776 1.58817 17.2966C1.58817 17.3166 1.59817 17.3366 1.61817 17.3466C3.41822 18.6662 5.14826 19.466 6.85831 19.9959C6.88831 20.0059 6.91831 19.9959 6.92831 19.9759C7.32832 19.426 7.68833 18.8462 7.99834 18.2363C8.01834 18.1963 7.99834 18.1563 7.95834 18.1463C7.38833 17.9264 6.84831 17.6665 6.3183 17.3665C6.2783 17.3466 6.2783 17.2866 6.3083 17.2566C6.4183 17.1766 6.5283 17.0866 6.6383 17.0066C6.65831 16.9866 6.68831 16.9866 6.70831 16.9966C10.1484 18.5662 13.8585 18.5662 17.2586 16.9966C17.2786 16.9866 17.3086 16.9866 17.3286 17.0066C17.4386 17.0966 17.5486 17.1766 17.6586 17.2666C17.6986 17.2966 17.6986 17.3565 17.6486 17.3765C17.1286 17.6865 16.5786 17.9364 16.0086 18.1563C15.9686 18.1663 15.9586 18.2163 15.9686 18.2463C16.2886 18.8562 16.6486 19.436 17.0386 19.9859C17.0686 19.9959 17.0986 20.0059 17.1286 19.9959C18.8486 19.466 20.5787 18.6662 22.3787 17.3466C22.3987 17.3366 22.4087 17.3166 22.4087 17.2966C22.8487 12.7677 21.6787 8.83878 19.3087 5.34969C19.2987 5.3397 19.2887 5.3297 19.2687 5.3297V5.3297ZM8.51836 14.9072C7.48833 14.9072 6.6283 13.9574 6.6283 12.7877C6.6283 11.6181 7.46833 10.6683 8.51836 10.6683C9.57839 10.6683 10.4184 11.628 10.4084 12.7877C10.4084 13.9574 9.56839 14.9072 8.51836 14.9072ZM15.4885 14.9072C14.4585 14.9072 13.5985 13.9574 13.5985 12.7877C13.5985 11.6181 14.4385 10.6683 15.4885 10.6683C16.5486 10.6683 17.3886 11.628 17.3786 12.7877C17.3786 13.9574 16.5486 14.9072 15.4885 14.9072Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        className="transition duration-300 ease-out hover:text-brown-light"
                                        href="https://avantarte.com/">
                                        <svg
                                            fill="none"
                                            height="60"
                                            viewBox="0 0 60 60"
                                            width="60"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                clipRule="evenodd"
                                                d="M35.2512 20.6223C34.1552 18.796 32.1628 17.6667 30.0003 17.6667C27.8378 17.6667 25.8455 18.796 24.7495 20.6223L17.1877 33.2223C16.0711 35.0829 16.048 37.3917 17.1267 39.2737C18.2069 41.1582 20.2323 42.3333 22.4386 42.3333H37.5621C39.7684 42.3333 41.7937 41.1582 42.874 39.2736C43.9527 37.3917 43.9296 35.0829 42.8129 33.2223L35.2512 20.6223ZM37.5377 19.2501C35.9561 16.6147 33.0924 15 30.0003 15C26.9082 15 24.0445 16.6147 22.4629 19.2501L14.9012 31.8501C13.2877 34.5387 13.254 37.8797 14.8131 40.5998C16.3723 43.3198 19.2839 45 22.4386 45H37.5621C40.7167 45 43.6284 43.3198 45.1875 40.5998C46.7466 37.8797 46.713 34.5387 45.0994 31.8501L37.5377 19.2501Z"
                                                fill="currentColor"
                                                fillRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed top-0 left-0 bottom-0 right-0 -z-10 col-span-12 md:relative md:col-span-4 lg:col-span-6">
                        <div className="absolute top-0 bottom-0 right-0 left-0 flex h-[70vh] items-center justify-start space-x-1 overflow-hidden md:h-[100vh]">
                            <div className="flex flex-1 flex-col items-center justify-center space-y-1">
                                {(imagesA || []).map((image, index) => {
                                    return (
                                        <div
                                            className={`relative w-full aspect-[${image.ratio}] overflow-hidden rounded-sm bg-brown-light/20`}>
                                            <img
                                                alt={image.alt}
                                                className="absolute h-full w-full object-cover"
                                                src={image.src}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex flex-1 flex-col items-center justify-center space-y-1 md:hidden lg:flex">
                                {(imagesB || []).map((image, index) => {
                                    return (
                                        <div
                                            className={`relative w-full aspect-[${image.ratio}] overflow-hidden rounded-sm bg-brown-light/20`}>
                                            <img
                                                alt={image.alt}
                                                className="absolute h-full w-full object-cover"
                                                src={image.src}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="hidden flex-1 flex-col items-center justify-center space-y-1 sm:flex md:hidden xl:flex">
                                {(imagesC || []).map((image, index) => {
                                    return (
                                        <div
                                            className={`relative w-full aspect-[${image.ratio}] overflow-hidden rounded-sm bg-brown-light/20`}>
                                            <img
                                                alt={image.alt}
                                                className="absolute h-full w-full object-cover"
                                                src={image.src}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
